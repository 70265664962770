import * as rolesModule from '@/store/modules/roles/RolesModule';
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ErrorResponse from "@/models/general/ErrorResponse";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { UserState } from "./auth/models/AuthState";
import { User } from "./auth/models/User";
import { encryptStorage } from '@/core/plugins/encryptStorage';
import AccountService from '@/core/services/account/AccountService';
import { RouteNames } from '@/router/route-names';
import router from '@/router';
import RefreshTokenRequest from '@/models/auth/RefreshTokenRequest';
import SwalMessageService from '@/core/services/SwalMessageService';

let intervalRefreshToken: NodeJS.Timer;
 
@Module
export default class AuthModule extends VuexModule implements UserState {
  errors = [] as ErrorResponse[];
  user = encryptStorage.getItem<User>("user") ?? {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }
  /**
   * Obtenemos el email del usuario logueado.
   */
  get getEmailUser(): string {
    return this.user?.userName ?? "";
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): ErrorResponse[] {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](errors: ErrorResponse[]) {
    this.errors = errors;
  }

  @Mutation
  [Mutations.SET_AUTH](user: User) {
    encryptStorage.setItem("user", user)
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(user.accessToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    //this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    
  }

  @Action
  async [Actions.LOGIN](credentials) {
    return accountService.login(credentials)
    .then(({data}) => {
        if(data){
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.dispatch(rolesModule.Actions.SET_ROLES_USER
          , data.roles)
          ApiService.setHeader();//para evitar perder el bearer
        } else {
          this.context.commit(Mutations.SET_ERROR, [{
            code: '0001'
            , description: 'Error al iniciar sesión'
          }]);
        }
    }).catch(() => {
      this.context.commit(Mutations.SET_ERROR, [{
        code: '0001'
        , description: 'Error al iniciar sesión'
      }]);
    });
  }

  @Action
  async [Actions.LOGOUT]() {
    return accountService.logout().then((isLogout) => {
      if(isLogout){
        this.context.commit(Mutations.PURGE_AUTH);
      }
      return isLogout;
    });
  }
  @Action
  [Actions.PURGE_AUTH](){
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      /*ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });*/
      console.log(
        "verificar autorizacion del token del usuario, mientras entra gratis! ;)"
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.REFRESH_TOKEN](){
    const request: RefreshTokenRequest = {
      accessToken: this.user.accessToken
      , refreshToken: this.user.refreshToken
    }
    const userCopy = this.user;
    return accountService.refreshToken(request).then((resp) => {
      if(resp && resp.data){
        const newUser = {
          ...userCopy
          , ...resp.data
        }
        this.context.commit(Mutations.SET_AUTH, newUser);
        ApiService.setHeader();//para evitar perder el bearer nuevo
        encryptStorage.setItem("loginTime", (new Date(new Date().setMilliseconds(+(process.env.VUE_APP_TIME_REFRESH_TOKEN ?? '300000')))));

      } else {
        //no se pudo refrescar el token... hay que cerrar sesión
        this.context.commit(Mutations.PURGE_AUTH);
        router.push({ name: RouteNames.SignIn });
        messageService.warning("No se tiene una sesión valida, no se pudo refrescar la sesión...");
      }
    });
  }
  @Action
  [Actions.START_COUNTER_REFRESH](){
    const milisecondaRefreshToken = +(process.env.VUE_APP_TIME_REFRESH_TOKEN ?? 300000);

    clearInterval(intervalRefreshToken);
    intervalRefreshToken = setInterval(() => {
      const loginEndTime = encryptStorage.getItem<number>("loginTime");
      const timeNow = new Date().getTime();
      const timeEnd = (loginEndTime ? new Date(loginEndTime) : new Date()).getTime();
      
      if((timeNow - timeEnd) > 0){
        this.context.dispatch(Actions.REFRESH_TOKEN)
      }
    }, (milisecondaRefreshToken/3));
  }
}
const accountService = new AccountService();
const messageService = new SwalMessageService();